var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"orderNo","hide-default-footer":"","items-per-page":100,"show-expand":""},on:{"item-expanded":function (ref) {
	var value = ref.value;
	var item = ref.item;

	return value && _vm.fetchSubTable(item);
}},scopedSlots:_vm._u([{key:"item.isMandatory",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.isMandatory ? _vm.$_strings.common.YES : _vm.$_strings.common.NO)+" ")])]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:"item.documentName",fn:function(ref){
var item = ref.item;
return [(item.url)?_c('a',{on:{"click":function($event){return _vm.openDocument(item)}}},[_vm._v(" "+_vm._s(item.descriptions)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.descriptions)+" ")])]}},{key:"item.actionColumn",fn:function(ref){
var item = ref.item;
return [(item.url)?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function () { return _vm.deleteFile(item); }}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('div',[_c('label',{staticClass:"custom-file-upload",attrs:{"for":"file-upload"}},[_c('v-btn',{ref:'btn-upload'+item.orderNo,attrs:{"for":'file-upload'+item.orderNo,"color":"primary","icon":"","loading":item.loading ? true : false},on:{"click":function($event){_vm.$refs['file-upload'+item.orderNo].click()}}},[_c('v-icon',[_vm._v("mdi-upload")])],1)],1),_c('input',{ref:'file-upload'+item.orderNo,staticStyle:{"display":"none"},attrs:{"id":'file-upload'+item.orderNo,"type":"file","accept":".pdf,.jpg,.jpeg,.png"},on:{"change":function (e) { return _vm.readFile(e, item); }}}),_c('br')])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var subItem = ref.item;
return [(subItem.subItemTableLoading)?[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","size":"30","indeterminate":""}})],1)],1)],1)]:(subItem.subItemTable)?[_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":headers,"items":subItem.subItemTable,"item-key":"name","hide-default-header":"","hide-default-footer":"","items-per-page":100},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(subHeader,index){return _c('th',{key:index,style:(!_vm.isMobile ? {width: subHeader.width} : null)})})}},{key:"item.documentName",fn:function(ref){
var item = ref.item;
return [(item.attachmentUrl)?_c('span',{staticClass:"ml-6"},[_c('a',{on:{"click":function($event){return _vm.openDocument(item)}}},[_vm._v(_vm._s(item.attachmentName))])]):_c('span',{staticClass:"red--text ml-6"},[_vm._v(_vm._s(item.attachmentName))]),(_vm.isMobile && item.attachmentUrl)?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function () { return _vm.setItemDelete(item); }}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.actionColumn",fn:function(ref){
var item = ref.item;
return [(!_vm.isMobile  && item.attachmentUrl)?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function () { return _vm.setItemDelete(item); }}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)})],1)]:_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"pt-2"},[_vm._v("Anda yakin akan menghapus \""+_vm._s(_vm.itemDelete.attachmentName)+"\"?")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Tidak")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.deleteFile(_vm.itemDelete)}}},[_vm._v("Ya")])],1)],1)]}}]),model:{value:(_vm.dialogDeleteDocument),callback:function ($$v) {_vm.dialogDeleteDocument=$$v},expression:"dialogDeleteDocument"}}),_c('dialog-view-image',{attrs:{"visible":_vm.dialogViewImage,"imageUrl":_vm.imageSelected},on:{"setDialog":_vm.setDialog}}),_c('dialog-view-pdf',{attrs:{"visible":_vm.dialogViewPdf,"urlPdf":_vm.urlPdfSelected},on:{"setDialog":_vm.setDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }