<template>
  <v-container fluid>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="items"
      item-key="orderNo"
      hide-default-footer
      :items-per-page="100"
      show-expand
      @item-expanded="({value, item}) => value && fetchSubTable(item)"
    >
      <template v-slot:[`item.isMandatory`]="{item}">
        <span>
          {{item.isMandatory ? $_strings.common.YES : $_strings.common.NO}}
        </span>
      </template>
      <template v-slot:[`item.no`]="{index}">
        <span>
          {{index + 1}}
        </span>
      </template>
      <template v-slot:[`item.documentName`]="{item}">
        <a
          v-if="item.url"
          @click="openDocument(item)"
        >
          {{item.descriptions}}
        </a>
        <span v-else>
          {{item.descriptions}}
        </span>
      </template>
      <template v-slot:[`item.actionColumn`]="{item}">
        <v-btn
          v-if="item.url"
          color="primary"
          icon
          @click="() => deleteFile(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <div>
          <label
            for="file-upload"
            class="custom-file-upload"
          >
            <v-btn
              :for="'file-upload'+item.orderNo"
              color="primary"
              icon
              :loading="item.loading ? true : false"
              :ref="'btn-upload'+item.orderNo"
              @click="$refs['file-upload'+item.orderNo].click()"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </label>
          <input
            :id="'file-upload'+item.orderNo"
            :ref="'file-upload'+item.orderNo"
            type="file"
            @change="e => readFile(e, item)"
            style="display: none;"
            accept=".pdf,.jpg,.jpeg,.png"
          />
          <br>
        </div>
      </template>

      <template
        v-slot:expanded-item="{ headers, item: subItem }"
      >
        <template v-if="subItem.subItemTableLoading">
          <td :colspan="headers.length">
            <v-row justify="center">
              <v-col cols="auto">
                <v-progress-circular
                  class="mx-auto"
                  color="primary"
                  size="30"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-else-if="subItem.subItemTable">
          <td
            :colspan="headers.length"
            class="px-0"
          >
            <v-data-table
              :headers="headers"
              :items="subItem.subItemTable"
              item-key="name"
              hide-default-header
              hide-default-footer
              :items-per-page="100"
            >
              <template v-slot:header="{props}">
                <th
                  v-for="(subHeader, index) in props.headers"
                  :key="index"
                  :style="!isMobile ? {width: subHeader.width} : null"
                ></th>
              </template>
              <template v-slot:[`item.documentName`]="{item}">
                <span class="ml-6" v-if="item.attachmentUrl">
                  <a @click="openDocument(item)">{{item.attachmentName}}</a>
                </span>
                <span v-else class="red--text ml-6">{{item.attachmentName}}</span>
                <v-btn
                  v-if="isMobile && item.attachmentUrl"
                  color="primary"
                  icon
                  @click="() => setItemDelete(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.actionColumn`]="{item}">
                <v-btn
                  v-if="!isMobile  && item.attachmentUrl"
                  color="primary"
                  icon
                  @click="() => setItemDelete(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </td>
        </template>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogDeleteDocument"
      transition="dialog-top-transition"
      max-width="400"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-text>
            <div class="pt-2">Anda yakin akan menghapus "{{itemDelete.attachmentName}}"?</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog.value = false"
            >Tidak</v-btn>
            <v-btn
              text
              @click="deleteFile(itemDelete)"
            >Ya</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <dialog-view-image :visible="dialogViewImage" @setDialog="setDialog" :imageUrl="imageSelected"/>
    <dialog-view-pdf :visible="dialogViewPdf" @setDialog="setDialog" :urlPdf="urlPdfSelected"/>

  </v-container>
</template>

<script>
export default {
  name: 'LegalDocuments',
  data() {
    return {
      isLoading: false,
      isAgree: false,
      items: [],
      itemDelete: {},
      dialogDeleteDocument: false,
      dialogViewImage: false,
      dialogViewPdf: false,
      imageSelected: '',
      urlPdfSelected: '',
      headers: [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.legalDocument.NO_TABLE_HEADER_LABEL,
          value: 'no',
          cellClass: 'w-10',
          sortable: false,
        },
        {
          text: this.$_strings.legalDocument.NAME_TABLE_HEADER_LABEL,
          value: 'documentName',
          sortable: false,
        },
        {
          text: this.$_strings.legalDocument.MANDATORY_TABLE_HEADER_LABEL,
          value: 'isMandatory',
          cellClass: 'w-20',
          sortable: false,
        },
        {
          text: this.$_strings.legalDocument.ACTION_TABLE_HEADER_LABEL,
          value: 'actionColumn',
          align: 'center',
          class: 'white--text primary text-capitalize',
          cellClass: 'w-10',
          sortable: false,
        },
      ],
      formSubmission: [],
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        // cellClass: 'clickable',
      }));
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    fetchListLegalDocument() {
      this.isLoading = true;
      this.$_services.legalDocument.getLegalDocumentList()
        .then((result) => {
          const dataIsShow = result.data.filter((dataLegal) => dataLegal.isShow === true);
          this.$set(this, 'items', dataIsShow);
        }).finally((i) => {
          this.isLoading = false;
        });
    },
    readFile(ev, item) {
      const file = ev.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();
      const fileSize = file.size;
      const fileName = file.name;
      const reader = new FileReader();
      reader.onload = (e) => {
        if (fileSize > 5000000) {
          this.$dialog.notify.error('Ukuran file tidak boleh lebih dari 5000kb.', { timeout: 5000 });
        } else {
          this.setFile(e.target.result.split(',')[1], fileSize, fileType, item, fileName);
        }
      };
      reader.readAsDataURL(file);
    },
    setFile(endcodeFile, size, fileType, item, fileName) {
      this.$set(item, 'loading', true);
      this.$_services.uploads.uploadFile(endcodeFile, fileName)
        .then((resultUplaod) => {
          const formSubmission = [];
          formSubmission.push({
            refId: item.id,
            attachmentUrl: resultUplaod.data.url,
            attachmentName: fileName,
            attachmentSize: size,
            fileType,
          });
          this.$_services.legalDocument.saveAttachment(formSubmission)
            .then((e) => {
              this.$set(item, 'encodedFile', endcodeFile);
              this.$set(item, 'size', size);
              this.$set(item, 'type', fileType);
              this.$set(item, 'fileName', fileName);
              this.$emit('input', resultUplaod.data.url);
              this.$set(item, 'subItemTable', '');
              this.$dialog.notify.success(`Berhasil mengunggah ${fileName}`);
              this.fetchSubTable(item);
            }).finally(() => this.$set(item, 'loading', false));
        }).catch((err) => {
          this.$message.error('Gagal mengunggah file');
        })
        .finally((i) => {
          this.$set(item, 'loading', false);
        });
    },
    fetchSubTable(item) {
      if (item.subItemTable) return;
      this.$set(item, 'subItemTableLoading', true);
      const documentSupportId = item.id;
      this.$_services.legalDocument.getListAttachment(documentSupportId)
        .then((res) => {
          let contents = [];
          if (res.data) {
            contents = res.data.map((i) => ({ ...i, type: 'Sub Table' }));
          } else {
            contents.push({
              attachmentName: 'Tidak ada data',
            });
          }
          this.$set(item, 'subItemTable', contents);
        })
        .finally(() => {
          this.$set(item, 'subItemTableLoading', false);
        });
    },
    deleteFile(item) {
      this.dialogDeleteDocument = false;
      const fileAttachmentId = item.id;
      this.$root.$loading.show();
      this.$_services.legalDocument.deleteAttachment(fileAttachmentId)
        .then(() => {
          this.$dialog.notify.success(`Berhasil menghapus ${item.attachmentName}`);
          this.items.forEach((items, index) => {
            if (items.subItemTable) {
              this.items[index].subItemTable = items.subItemTable.filter((subItem) => subItem.id !== fileAttachmentId);
            }
          });
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
    setItemDelete(item) {
      this.itemDelete = item;
      this.dialogDeleteDocument = true;
    },
    openDocument(item) {
      const { fileType, attachmentUrl } = item;
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        this.dialogViewImage = true;
        this.imageSelected = attachmentUrl;
        return;
      }
      if (fileType === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = attachmentUrl;
        return;
      }
      window.open(attachmentUrl);
    },
  },
  mounted() {
    this.fetchListLegalDocument();
  },
};
</script>

<style>
</style>
